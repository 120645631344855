import { useState } from "react";
import getIrpefLordaRegionale from "./irpefLordaRegionale";

const limite_forfettario = 85000

/* http://www.fondopriamo.it/blog/priamo/calcolo-tfr */
const getTFR = (ral = 0, anni = 1) => {
  let tfrLordo = Number(ral) / 13.5;
  tfrLordo -= (Number(ral) / 100) * 0.5;

  tfrLordo *= anni;
  const redditoDiRiferimento = (tfrLordo * 12) / anni;

  let tfrImpostaLorda = getIrpef(redditoDiRiferimento);
  let aliquota = tfrImpostaLorda / redditoDiRiferimento || 0;
  let tfrNetto = tfrLordo - tfrLordo * aliquota;

  return {
    netto: tfrNetto,
    lordo: tfrLordo,
  };
};

/* Calcolo IRPEF
  da 0 8174 => 0 taxfree
  da 8174 a 15.000 => 23%
  da 15.000 a 28.000 => 27% sulla parte eccedente i 15.000
  da 28.000 a 55.000 => 38% sulla parte eccedente i 28.000
  da 55.000 a 75.000 => 41% sulla parte eccedente i 55.000
  da 75.000 in poi => 43% sulla parte eccedente i 75.000
*/
const getIrpef = (redditoImponibile) => {
  let irpef = 0;

  /*
  Se hai già reddito da lavoro dipendente superiore a 60.000S,
  sommenta questa riga per simulare una tassazione marginale del 43%
  irpef = redditoImponibile * 0.43;
  return;
  */

  if (redditoImponibile <= 8174) {
    irpef = 0;
  } else if (redditoImponibile <= 15000) {
    irpef = redditoImponibile * 0.23;
  } else if (redditoImponibile <= 28000) {
    irpef = 3450 + (redditoImponibile - 15000) * 0.27;
  } else if (redditoImponibile <= 55000) {
    irpef = 6960 + (redditoImponibile - 28000) * 0.38;
  } else if (redditoImponibile <= 75000) {
    irpef = 17220 + (redditoImponibile - 55000) * 0.41;
  } else if (redditoImponibile > 75000) {
    irpef = 25420 + (redditoImponibile - 75000) * 0.43;
  }

  return irpef;
};

const getDetrazioni = (redditoImponibile, giorni) => {
  let detrazioni = 0;

  if (redditoImponibile <= 0) return 0;

  if (redditoImponibile <= 8000) {
    detrazioni = 1880 * (giorni / 365);
  } else if (redditoImponibile <= 28000) {
    const quoziente = (28000 - redditoImponibile) / 20000;
    detrazioni = (978 + 902 * quoziente) * (giorni / 365);
  } else if (redditoImponibile <= 55000) {
    const quoziente = (55000 - redditoImponibile) / 27000;
    detrazioni = 978 * quoziente * (giorni / 365);
  }

  return detrazioni;
};

const Assunzione = ({ ral, giorni = 365, regione, rientro = false }) => {
  const contributiDatore = ral * 0.31;
  const contributiLavoratore = ral * 0.0949;
  let redditoImponibile = Number(ral) - contributiLavoratore;

  if (rientro) {
    redditoImponibile = redditoImponibile / 2;
  }

  const irpefLordaRegionale = getIrpefLordaRegionale(
    regione,
    redditoImponibile
  );
  const irpefLordaComunale = (redditoImponibile * 0.2) / 100;
  const irpefLorda =
    getIrpef(redditoImponibile, rientro) +
    irpefLordaRegionale +
    irpefLordaComunale;

  const irpefNetta = irpefLorda - getDetrazioni(redditoImponibile, giorni);

  const { lordo: tfrLordo, netto: tfrNetto1 } = getTFR(ral, 1);
  const { netto: tfrNetto2 } = getTFR(ral, 2);
  const { netto: tfrNetto3 } = getTFR(ral, 3);

  const costoAziendale = Number(ral) + contributiDatore + tfrLordo;

  const netto =
    (rientro ? redditoImponibile * 2 : redditoImponibile) - irpefNetta;

  return (
    <div>
      <div>
        Costo aziendale: <strong>{costoAziendale.toFixed(2)} €</strong>
      </div>
      <div>
        Contributi previdenziali datore:{" "}
        <strong>{contributiDatore.toFixed(2)} €</strong>
      </div>
      <div>
        Contributi previdenziali lavoratore:{" "}
        <strong>{contributiLavoratore.toFixed(2)} €</strong>
      </div>
      <div>
        IRPEF Netta: <strong>{irpefNetta.toFixed(2)} €</strong>
      </div>
      <div>
        TFR lordo annuo: <strong>{tfrLordo.toFixed(2)} €</strong>
      </div>
      <div>
        TFR netto 1 anno: <strong>{tfrNetto1.toFixed(2)} €</strong>
      </div>
      <div>
        TFR netto 2 anni: <strong>{tfrNetto2.toFixed(2)} €</strong>
      </div>
      <div>
        TFR netto 3 anni: <strong>{tfrNetto3.toFixed(2)} €</strong>
      </div>
      <div>
        Netto: <strong>{netto.toFixed(2)} €</strong>
      </div>
      <div>
        Al mese * 12: <strong>{(netto / 12).toFixed(2)} €</strong>
      </div>
      <div>
        Al mese * 13: <strong>{(netto / 13).toFixed(2)} €</strong>
      </div>
      <div>
        Al mese * 14: <strong>{(netto / 14).toFixed(2)} €</strong>
      </div>
    </div>
  );
};

const Ordinaria = ({ ral, oneri, giorni = 365, rientro = false }) => {
  const contributi = Number(ral) * 0.2598;
  let redditoImponibile = Number(ral) - contributi;

  if (rientro) {
    redditoImponibile *= 0.5;
  }

  // const irap = redditoImponibile * 0.039;
  // redditoImponibile -= irap;

  let irpefLorda = getIrpef(Number(redditoImponibile) - Number(oneri));
  let irpefNetta = irpefLorda - getDetrazioni(redditoImponibile, giorni);

  const netto =
    (rientro ? redditoImponibile * 2 : redditoImponibile) - irpefNetta;

  return (
    <div>
      <div>
        Utile lordo: <strong>{Number(ral).toFixed(2)} €</strong>
      </div>
      <div>
        Contributi previdenziali: <strong>{contributi.toFixed(2)} €</strong>
      </div>
      {/* <div>IRAP: <strong>{irap.toFixed(2)} €</strong></div> */}
      <div>
        IRPEF Netta: <strong>{irpefNetta.toFixed(2)} €</strong>
      </div>
      <div>
        Netto: <strong>{netto.toFixed(2)} €</strong>
      </div>
      <div>
        Al mese * 12: <strong>{(netto / 12).toFixed(2)} €</strong>
      </div>
    </div>
  );
};

const Forfettaria = ({ ral, ateco, tax, contributi }) => {
  const spese = ral * (1 - ateco);

  const imponibile = ral - spese;

  const calculatedContributi = imponibile * contributi;

  const calculatedTax = (imponibile - calculatedContributi) * tax;

  const netto = ral - calculatedContributi - calculatedTax;

  return (
    <div>
      <div>
        Tassa su Imponibile: <strong>{calculatedTax.toFixed(2)} €</strong>
      </div>
      <div>
        Contributi previdenziali:
        <strong>{calculatedContributi.toFixed(2)} €</strong>
      </div>
      <div>
        Netto: <strong>{netto.toFixed(2)} €</strong>
      </div>
      <div>
        Al mese * 12: <strong>{(netto / 12).toFixed(2)} €</strong>
      </div>
    </div>
  );
};

function App() {
  const [ral, setRal] = useState(0);
  const [ateco, setAteco] = useState(0.67);
  const [oneri, setOneri] = useState(0);
  const [regione, setRegione] = useState("piemonte");
  const [rientro, setRientro] = useState(false);

  return (
    <div>
      <h1>Tax Calculator {new Date().getFullYear()}</h1>
      <div>
        <label>Stipendio Annuo Lordo</label>
        <br />
        <input
          onClick={(e) => e.target.select()}
          type="number"
          placeholder="RAL"
          onChange={(e) => setRal(e.target.value)}
          value={ral}
        ></input>
      </div>
      <div>
        <label>Regione</label>
        <br />
        <select
          defaultValue={regione}
          onChange={(e) => setRegione(e.target.value)}
          name="regione"
        >
          <option value="abruzzo">Abruzzo</option>
          <option value="basilicata">Basilicata</option>
          <option value="bolzano">Provincia di Bolzano</option>
          <option value="calabria">Calabria</option>
          <option value="campania">Campania</option>
          <option value="emiliaRomagna">Emilia Romangna</option>
          <option value="friuliVenezia">Friuli Venezia Giulia</option>
          <option value="lazio">Lazio</option>
          <option value="liguria">Liguria</option>
          <option value="lombardia">Lombardia</option>
          <option value="marche">Marche</option>
          <option value="molise">Molise</option>
          <option value="piemonte">Piemonte</option>
          <option value="puglia">Puglia</option>
          <option value="sardegna">Sardegna</option>
          <option value="sicilia">Sicilia</option>
          <option value="toscana">Toscana</option>
          <option value="trentino">Provincia di Trento</option>
          <option value="umbria">Umbria</option>
          <option value="aosta">Valle d'Aosta</option>
          <option value="veneto">Veneto</option>
        </select>
      </div>
      <div>
        <label className="checkbox">
          <input
            type="checkbox"
            onChange={(e) => setRientro(e.target.checked)}
          ></input>{" "}
          Regime Lavoratori Impatriati 2024 (50%){" "}
        </label>
      </div>
      <fieldset>
        <h2>Forfettario</h2>
        <div>
          <label>Codice ateco</label>
          <br />
          <select
            onChange={(e) => setAteco(e.target.value)}
            defaultValue={ateco}
          >
            <option>Seleziona</option>
            <option value={0.78}>Web Designer</option>
            <option value={0.67}>Web Developer</option>
          </select>
        </div>
        <div className="taxs">
          <div className={`tax ${ral > limite_forfettario ? "disabled" : ""}`}>
            <h2>{ral > limite_forfettario ? <del>5%</del> : `5%`}</h2>
            <Forfettaria ral={ral} ateco={ateco} tax={0.05} contributi={0.2607} />
          </div>
          <div className={`tax ${ral > limite_forfettario ? "disabled" : ""}`}>
            <h2>{ral > limite_forfettario ? <del>15%</del> : `15%`}</h2>
            <Forfettaria ral={ral} ateco={ateco} tax={0.15} contributi={0.2607} />
          </div>
        </div>
      </fieldset>
      <fieldset>
        <h2>Ordinario</h2>
        <div>
          <label>Oneri deducibili</label>
          <br />
          <input
            onClick={(e) => e.target.select()}
            type="number"
            placeholder="Oneri deducibili"
            onChange={(e) => setOneri(e.target.value)}
            value={oneri}
          ></input>
        </div>
        <Ordinaria ral={ral} oneri={oneri} rientro={rientro} />
      </fieldset>
      <fieldset>
        <h2>Assunzione</h2>
        <Assunzione ral={ral} regione={regione} rientro={rientro} />
      </fieldset>
    </div>
  );
}

export default App;
